import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import Logo from '../assets/light-logo.png'

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLinksWrapper>
        <FooterDescription>
          <Link to="/"><img src={Logo} alt="Logo" /></Link>
          <p>Feito com 💙 no Brasil</p>
          <p>© privetly, 2022. Todos os direitos reservados</p>
        </FooterDescription>
        <FooterLinkItems>
          <FooterLinkTitle>Legal</FooterLinkTitle>
          <FooterLink to="/terms">Termos</FooterLink>
          <FooterLink to="/privacy">Privacidade</FooterLink>
        </FooterLinkItems>
      </FooterLinksWrapper>
      <FooterLinksWrapper>
        <FooterLinkItems>
          <FooterLinkTitle>Como funciona</FooterLinkTitle>
          <FooterLink to="/creators">Para Criadores</FooterLink>
          <FooterLink to="/followers">Para Seguidores</FooterLink>
        </FooterLinkItems>
        <FooterLinkItems>
          <FooterLinkTitle>Precisa de ajuda?</FooterLinkTitle>
          <FooterLink as="a" href="mailto:ajuda@privetly.com">Entre em Contato</FooterLink>
          <FooterLink to="/faq">FAQ</FooterLink>
        </FooterLinkItems>
      </FooterLinksWrapper>
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled.div`
  padding: 5rem calc((100vw - 1100px) / 2);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: #fff;
  background: #141414;
  
`

const FooterDescription = styled.div`
  padding: 0 2rem;
  font-size: 12px;

  img {
    margin-bottom: 0.5rem;
    width: 130px;
  }

  @media screen and (max-width: 400px) {
    padding: 1rem;
  }
`

const FooterLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;

  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr;
  }
`

const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 2rem;

  @media screen and (max-width: 400px) {
    padding: 1rem;
  }
`

const FooterLinkTitle = styled.h2`
  font-size: 14px;
  margin-bottom: 16px;
`

const FooterLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: #DECFFF;

  &:hover {
    color: #B393FF;
    transition: 0.3s ease-out;
  }
`
