import styled from "styled-components";
import {Link} from "gatsby";

export const Button = styled(Link)`
  background: ${({primary}) => (primary ? '#421C99' : '#5756E0')};
  white-space: nowrap;
  padding: ${({big}) => (big ? '16px 40px' : '10px 32px')};
  color: #fff;
  font-size: ${({big}) => (big ? '20px' : '16px')};
  outline: none;
  border: none;
  min-width: 100px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s !important;
  border-radius: ${({round}) => (round ? '50px' : 'none')};
  display: flex;
  align-items: center;

  &:hover {
    background: ${({primary}) => (primary ? '#5756E0' : '#421C99')};
    transform: translateY(-2px);
  }
`